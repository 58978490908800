import { OzTicketCff } from '../../../js/Domain/Ticket/struct';
import { UserPage } from '../../../js/Base/UserPage';

export function _WebApi(apiType, apiMethod, param, errorState) {
  return new UserPage().WebApi(
    apiType,
    apiMethod,
    param,
    (res) => {
      return res;
    },
    (err) => {
      if (errorState) {
        err = {
          msg: errorState(err.response.data.code),
          code: err.response.data.code,
        };
      }
      throw err;
    }
  );
}

export class OzApiProvider extends UserPage {
  _WebApi(apiType, apiMethod, param, errorState) {
    return this.WebApi(
      apiType,
      apiMethod,
      param,
      (res) => {
        // console.log(res.data.data);
        return res;
      },
      (err) => {
        if (errorState) {
          err = {
            msg: errorState(err.response.data.code),
            code: err.response.data.code,
          };
        }
        throw err;
      }
    );
  }
  editCompanyGroup(group) {
    const errorState = (code) => {
      switch (code) {
        case '1':
          return '該部門存在子部門，必須先刪除子部門';
        case '2':
          return '最上層部門已經存在';
        case '3':
          return '最上層部門不可設定上層部門';
        default:
          return null;
      }
    };

    return this._WebApi('comp', 'EditCompanyGroup', { company_group: group }, errorState);
  }

  findCompanyGroupsByNoList(noList) {
    return this._WebApi('comp', 'FindCompanyGroupsByNoList', { no_list: noList });
  }

  GetAccountLinkByProvider(provider = null) {
    return this._WebApi('azure', 'GetAccountLinkByProvider', { provider: provider });
  }

  AppKeyAddToCloudGoda(item = null) {
    return this._WebApi('azure', 'AppKeyAddToCloudGoda', item);
  }

  FindAllAppKey(projectNo = null) {
    return this._WebApi('azure', 'FindAllAppKey', { project_no: projectNo });
  }
  UpdateAppKey(key = null) {
    return this._WebApi('azure', 'UpdateAppKey', { app_key: key });
  }

  InsertAppKey(key = null) {
    return this._WebApi('azure', 'InsertAppKey', { app_key: key });
  }

  DeleteAppKey(id = null, project_no) {
    return this._WebApi('azure', 'DeleteAppKey', { subscription_id: id, project_no: project_no });
  }

  FindSecurityAssistantContentByTicketNo(ticket_no) {
    return this._WebApi('ticket', 'FindSecurityAssistantContentByTicketNo', { ticket_no: ticket_no });
  }

  FindTeamsAuthKeyList(projectNo = null) {
    return this._WebApi('teams', 'FindTeamsAuthKeyList', { project_no: projectNo });
  }

  FindAllCloudConfigKeys(projectNo = null) {
    return this._WebApi('configKey', 'GetAllConfigKeys', {});
  }

  UpdateCloudConfigKey(key = null) {
    return this._WebApi('configKey', 'UpdateConfigKey', { config_key: key });
  }

  InsertCloudConfigKey(key = null) {
    return this._WebApi('configKey', 'InsertConfigKey', { config_key: key });
  }

  DeleteCloudConfigKey(key_name, project_no) {
    return this._WebApi('configKey', 'DeleteConfigKey', { key_name: key_name, project_no: project_no });
  }
  FindAllSecretsFromKeyVault(projectNo = null) {
    return this._WebApi('configKey', 'GetAllSecretsFromKeyVault', {});
  }
  DeleteSecretFromKeyVault(secret_name) {
    return this._WebApi('configKey', 'DeleteSecretsKeyVault', { secret_name: secret_name });
  }
  InsertOrUpdateSecretKeyVault(key) {
    return this._WebApi('configKey', 'SetOrUpdateSecretKeyVault', { secret: key });
  }

  FindProjectMembersByProjectNo(page, page_size, search, project_no) {
    const data = {
      project_no: project_no,
      page: page,
      page_size: page_size,
      search: search,
    };
    return this._WebApi('proj', 'FindProjectMembersByProjectNo', data);
  }

  FindProjectByAcctNo(acct_no) {
    return this._WebApi('proj', 'FindProjectByAcctNo', { acct_no });
  }

  findAllAccount(page, page_size, search) {
    const data = {
      page: page,
      page_size: page_size,
      search: search,
    };
    return this._WebApi('acct', 'FindAllAccount', data);
  }

  findAccountByNo(acctNo) {
    return this._WebApi('acct', 'FindAccountByNo', { acct_no: acctNo });
  }

  findAccountByAccountID(acct_id) {
    return this._WebApi('acct', 'FindAccountByID', { acct_id });
  }

  editAccount(account) {
    return this._WebApi('acct', 'EditAccount', { acct: account });
  }

  editCompany(company) {
    return this._WebApi('comp', 'EditCompany', { company: company });
  }

  findAllCompany(page = 1, pageSize = 10, search = null) {
    const data = {
      page: page,
      page_size: pageSize,
      search: search,
    };

    return this._WebApi('comp', 'FindAllCompany', data);
  }

  findCompanysByAccountNo(accountNo) {
    return this._WebApi('comp', 'FindCompanysByAcct', { acct_no: accountNo });
  }

  findCompanysByNoList(companyNoList) {
    return this._WebApi('comp', 'FindCompanysByNoList', { no_list: companyNoList });
  }

  createCompanyConfiguration = (companyCfg) => {
    const data = { company_cfg: companyCfg };
    return this._WebApi('comp', 'CreateCompanyConfiguration', data);
  };

  editCompanyConfiguration = (companyCfg) => {
    console.log(companyCfg);
    const data = { company_cfg: companyCfg };
    return this._WebApi('comp', 'EditCompanyConfiguration', data);
  };

  findManageableCompanies(accountNo) {
    return this._WebApi('comp', 'FindManageableCompanies', { acct_no: accountNo });
  }

  findCompanyMembersByCompanyNo(companyNo, page, pageSize, searchKeyword) {
    const data = {
      company_no: companyNo,
      page: page,
      page_size: pageSize,
    };
    return this._WebApi('comp', 'FindCompanyMembersByCompanyNo', data);
  }

  findCompanyMembersNotInProject(companyNo, projectNo, page, pageSize, searchKeyword) {
    const data = {
      company_no: companyNo,
      project_no: projectNo,
      page: page,
      page_size: pageSize,
      search: searchKeyword,
    };
    return this._WebApi('comp', 'FindCompanyMembersNotInProject', data);
  }

  editCompanyMember(companyMember) {
    return this._WebApi('comp', 'EditCompanyMember', { company_member: companyMember });
  }

  editCompanyMemberRole(company_member_no, is_set_sub_sys_owner) {
    const errorState = (code) => {
      switch (code) {
        case '1':
          return '不可刪除自己的管理權限';
        default:
          return null;
      }
    };

    const data = {
      company_member_no: company_member_no,
      is_set_sub_sys_owner: is_set_sub_sys_owner,
    };

    return this._WebApi('comp', 'EditCompanyMemberRole', data, errorState);
  }

  editCompanyMemberRoleByList(company_member_list, is_set_sub_sys_owner) {
    const errorState = (code) => {
      switch (code) {
        case '1':
          return '不可刪除自己的管理權限';
        default:
          return null;
      }
    };

    const data = {
      company_member_list: company_member_list,
      is_set_sub_sys_owner: is_set_sub_sys_owner,
    };

    return this._WebApi('comp', 'EditCompanyMemberRoleByList', data, errorState);
  }

  findAllCompGroupsByCompNo(companyNo, page, pageSize, search = null) {
    const data = {
      com_no: companyNo,
      page: page,
      page_size: pageSize,
      search: search,
    };
    return this._WebApi('comp', 'FindAllCompGroupsByCompNo', data);
  }

  findSubCompanyGroupsByParentNo(companyNo = null, parentNo = null) {
    let data = {};
    if (companyNo === null) {
      data = { parent_no: parentNo };
    } else {
      data = { company_no: companyNo };
    }
    return this._WebApi('comp', 'FindSubCompanyGroupsByParentNo', data);
  }

  findCompanyGroupMembersByCompanyGroupNo(compGroupNo, page, pageSize = 10, search = null) {
    const data = {
      company_group_no: compGroupNo,
      page: page,
      page_size: pageSize,
      search: search,
    };
    return this._WebApi('comp', 'FindCompanyGroupMembersByCompanyGroupNo', data);
  }

  findCompanyMembersByNoList(noList) {
    return this._WebApi('comp', 'FindCompanyMembersByNoList', { no_list: noList });
  }

  findAccountsByNoList(noList) {
    return this._WebApi('acct', 'FindAccountsByNoList', { acct_no_list: noList });
  }

  findCompanyMembersByAcctNo(accountNo) {
    return this._WebApi('comp', 'FindCompanyMembersByAcctNo', { acct_no: accountNo });
  }

  findCompanyWorkingWeekDayByCompNo(companyNo) {
    return this._WebApi('comp', 'FindCompanyWorkingWeekDayByCompNo', { comp_no: companyNo });
  }

  editCompanyWorkingWeekDay(companyWorkingWeekDay) {
    return this._WebApi('comp', 'EditCompanyWorkingWeekDay', { company_working_week_day: companyWorkingWeekDay });
  }

  createCompanyCalender(companyCompanyCalender) {
    return this._WebApi('comp', 'CreateCompanyCalender', { company_calender: companyCompanyCalender });
  }

  CreateCompanyCalenderByList(company_calender_list) {
    return this._WebApi('comp', 'CreateCompanyCalenderByList', { company_calender_list: company_calender_list });
  }

  findCompanyCalendersByCompNo(companyNo, page, pageSize) {
    const data = {
      comp_no: companyNo,
      page: page,
      page_size: pageSize,
    };
    return this._WebApi('comp', 'FindCompanyCalendersByCompNo', data);
  }

  deleteCompanyCalenderByNo(companyCompanyCalenderNo) {
    return this._WebApi('comp', 'DeleteCompanyCalenderByNo', { no: companyCompanyCalenderNo });
  }

  createProject(project) {
    return this._WebApi('proj', 'CreateProject', { project: project });
  }

  findProjectsByCompanyNo(companyNo, page, pageSize) {
    const data = {
      company_no: companyNo,
      page: page,
      page_size: pageSize,
    };
    return this._WebApi('proj', 'FindProjectsByCompanyNo', data);
  }

  findManageableProjectByCompanyNo(companyNo) {
    const data = {
      company_no: companyNo,
    };
    return this._WebApi('proj', 'FindManageableProjectByCompanyNo', data);
  }

  editProject(project) {
    return this._WebApi('proj', 'EditProject', { project: project });
  }

  findProjectMembersByProjectNo(projectNo, page, pageSize, search_keyword = null) {
    const data = {
      project_no: projectNo,
      page: page,
      page_size: pageSize,
      search: search_keyword,
    };

    return this._WebApi('proj', 'FindProjectMembersByProjectNo', data);
  }

  createProjectMember(projectNo, companyMemberNo, projectRoleNoList, godaRoleNoList, joinDt) {
    const data = {
      project_member: {
        project_no: projectNo,
        company_member_no: companyMemberNo,
        status: 1,
        join_dt: joinDt,
      },
      project_role_no_list: projectRoleNoList,
      goda_role_no_list: godaRoleNoList,
    };

    return this._WebApi('proj', 'CreateProjectMember', data);
  }

  editProjectMember(projectMember) {
    return this._WebApi('proj', 'EditProjectMember', { project_member: projectMember });
  }

  findProjectRolesByProjectNo(projectNo) {
    return this._WebApi('proj', 'FindProjectRolesByProjectNo', { project_no: projectNo });
  }

  findVIPUserByProjectNo(projectNo) {
    return this._WebApi('vip', 'FindVIPUserByProjectNo', { project_no: projectNo });
  }

  createVIPUserByProjectNo(vipObj) {
    return this._WebApi('vip', 'CreateVIPUser', { vip_user: vipObj });
  }

  updateVIPUserByProjectNo(vipObj) {
    return this._WebApi('vip', 'EditVIPUser', { vip_user: vipObj });
  }

  deleteVIPUserByProjectNo(no) {
    return this._WebApi('vip', 'DeleteVIPUser', { no: no });
  }

  createProjectRole(projectNo, name, desc, authorityCode, dashboardAuth) {
    const data = {
      project_role: {
        project_no: projectNo,
        name: name,
        desc: desc,
        authority_code: authorityCode,
        dashboard_auth_type: dashboardAuth,
      },
    };

    return this._WebApi('proj', 'CreateProjectRole', data);
  }

  editProjectRole(projectRole) {
    return this._WebApi('proj', 'EditProjectRole', { project_role: projectRole });
  }

  findProjectMemberRolesByProjectMemberNo(projectMemberNo) {
    return this._WebApi('proj', 'FindProjectMemberRolesByProjectMemberNo', { project_member_no: projectMemberNo });
  }

  findAllProjectAuthTypes() {
    return this._WebApi('proj', 'FindAllProjectAuthTypes', {});
  }

  createProjectMemberRole(projectMemberNo, roleNo) {
    const data = {
      project_member_no: projectMemberNo,
      role_no: roleNo,
    };
    return this._WebApi('proj', 'CreateProjectMemberRole', data);
  }

  updateProjectMemberRole(projectMemberNo, originRoleNoList, roleNoList) {
    const data = {
      project_member_no: projectMemberNo,
      origin_role_no_list: originRoleNoList,
      role_no_list: roleNoList,
    };
    return this._WebApi('proj', 'UpdateProjectMemberRole', data);
  }

  deleteProjectMemberRole(projectMemberNo, roleNo) {
    const data = {
      project_member_no: projectMemberNo,
      role_no: roleNo,
    };
    return this._WebApi('proj', 'DeleteProjectMemberRole', data);
  }
  GetDocumentSnInfoByProjectNo(projectNo = null) {
    return this._WebApi('report', 'GetDocumentSnInfoByProjectNo', { project_no: projectNo });
  }

  DeleteDocumentSnInfo(no) {
    return this._WebApi('report', 'DeleteDocumentSnInfo', {
      no: no,
    });
  }

  UpdateDocumentSnInfo(obj) {
    return this._WebApi('report', 'UpdateDocumentSnInfo', obj);
  }

  CreateDocumentSnInfo(obj) {
    return this._WebApi('report', 'CreateDocumentSnInfo', obj);
  }

  GetMultiWorkspaceList(projectNo = null) {
    return this._WebApi('report', 'GetMultiWorkspaceList', { project_no: projectNo });
  }

  GetWorkspaceList(projectNo = null) {
    return this._WebApi('azure', 'FindAllWorkspaceByProjectNo', { project_no: projectNo });
  }
}

export class OzTicketApiProvider extends OzApiProvider {
  findTicketCategoryByProjectNo(projectNo) {
    const data = {
      project_no: projectNo,
    };
    return this._WebApi('ticket/setting', 'FindTicketCategoryByProjectNo', data);
  }

  createTicketCategory(name, desc, enable_change_ci, project_no) {
    const data = {
      ticket_category: {
        name: name,
        desc: desc,
        enable_change_ci: enable_change_ci,
        project_no: project_no,
      },
    };
    return this._WebApi('ticket/setting', 'CreateTicketCategory', data);
  }

  editTicketCategory(category) {
    const data = {
      ticket_category: category,
    };

    return this._WebApi('ticket/setting', 'EditTicketCategory', data);
  }

  findTicketStatus(ticketCategoryNo) {
    const data = {
      ticket_category_no: ticketCategoryNo,
    };
    return this._WebApi('ticket/setting', 'FindTicketStatusByTicketCategoryNo', data);
  }

  createTicketStatus(name, sort, ticketCategoryNo, projectNo) {
    const data = {
      ticket_status: {
        name: name,
        sort: sort,
      },
      ticket_category_no: ticketCategoryNo,
      project_no: projectNo,
    };
    return this._WebApi('ticket/setting', 'CreateTicketStatus', data);
  }

  editTicketStatuses(ticketStatuses, ticketCategoryNo) {
    const data = {
      ticket_status_list: ticketStatuses,
      ticket_category_no: ticketCategoryNo,
    };
    return this._WebApi('ticket/setting', 'EditTicketStatusesByCategoryNo', data);
  }

  findTicketCategorySerialNumberByCategoryNo(ticketCategoryNo) {
    const data = {
      ticket_category_no: ticketCategoryNo,
    };
    return this._WebApi('ticket/setting', 'FindTicketCategorySerialNumberByCategoryNo', data);
  }

  createTicketCategorySerialNumber(code, newDate, digits, returnTo1Type, ticketCategoryNo, projectNo) {
    const data = {
      ticket_category_serial_num: {
        code: code,
        use_date: newDate,
        digits: digits,
        return_to_1_type: returnTo1Type,
        category_no: ticketCategoryNo,
        project_no: projectNo,
      },
    };

    return this._WebApi('ticket/setting', 'CreateTicketCategorySerialNumber', data);
  }

  editTicketCategorySerialNumber(ticketCategorySerialNum) {
    const data = {
      ticket_category_serial_num: ticketCategorySerialNum,
    };
    return this._WebApi('ticket/setting', 'EditTicketCategorySerialNumber', data);
  }

  findTicketClassByParentNo(ticketCategoryNo, parentNo) {
    const data = {
      ticket_category_no: ticketCategoryNo,
      parent_no: parentNo,
    };
    return this._WebApi('ticket/setting', 'FindTicketClassByParentNo', data);
  }

  createTicketClass(ticketClass) {
    const data = {
      ticket_class: ticketClass,
    };
    return this._WebApi('ticket/setting', 'CreateTicketClass', data);
  }

  updateTicketClassByWebApi(original_list, ticket_class_list) {
    const data = {
      original_list: original_list,
      ticket_class_list: ticket_class_list,
    };
    return this._WebApi('ticket/setting', 'UpdateTicketClass', data);
  }

  editTicketClass(ticket_class) {
    const data = {
      ticket_class: ticket_class,
    };
    return this._WebApi('ticket/setting', 'EditTicketClass', data);
  }

  findTicketCategoryLevelByCategoryNo(ticketCategoryNo) {
    const data = {
      category_no: ticketCategoryNo,
    };

    return this._WebApi('ticket/setting', 'FindTicketCategoryLevelByCategoryNo', data);
  }

  editTicketCategoryLevels(ticketCategoryLevelList, ticketCategoryNo) {
    const data = {
      ticket_category_level_list: ticketCategoryLevelList,
      ticket_category_no: ticketCategoryNo,
    };
    return this._WebApi('ticket/setting', 'EditTicketCategoryLevels', data);
  }

  getTicketCffByNo = async (no, process_disable = false, process_deleted = false) => {
    let tCffDict = await this._WebApi('ticket/cff', 'GetByNo', { no }).then((res) => res.data.data);
    const ticketCff = new OzTicketCff().fromDict(tCffDict);
    if (process_disable) {
      ticketCff.filterDisable();
    }
    if (process_deleted) {
      ticketCff.filterDelete();
    }

    return ticketCff;
  };

  getTicketCffByTicketNo = async ({ ticket_no, with_field_groups = false, with_ticket_category = false }) => {
    return await this._WebApi('ticket/cff', 'GetByTicketNo', {
      ticket_no,
      with_field_groups,
      with_ticket_category,
    });
  };

  getTicketCffByTicketClassNo = async ({
    ticket_category_no,
    ticket_class_no,
    with_field_groups = false,
    with_ticket_category = false,
    with_ticket_class = false,
  }) => {
    return await this._WebApi('ticket/cff', 'GetByCategoryNoClassNo', {
      ticket_category_no,
      ticket_class_no,
      with_field_groups,
      with_ticket_category,
      with_ticket_class,
    });
  };

  getTicketCffsByTicketCategoryNo = async ({
    ticketCategoryNo,
    with_relation_data,
    processDisable = false,
    processDeleted = false,
    filterTableColumn = true,
  }) => {
    const data = {
      category_no: ticketCategoryNo,
      with_relation_data,
    };

    let tCffDicts = await this._WebApi('ticket/cff', 'GetCffsByTicketCategoryNo', data).then((res) => res.data.data);

    // 過濾已停用、刪除的 ticket 自訂欄位
    if (processDisable) tCffDicts = tCffDicts.filter((cff) => cff.enable);
    if (processDeleted) tCffDicts = tCffDicts.filter((cff) => !cff.deleted);

    const ticketCffs = tCffDicts.map((tCffDict) => {
      const ticketCff = new OzTicketCff().fromDict(tCffDict);

      // 過濾已停用、刪除的欄位
      if (processDisable) ticketCff.filterDisable();
      if (processDeleted) ticketCff.filterDelete();
      if (filterTableColumn) ticketCff.filterTableColumn();

      return ticketCff;
    });

    return ticketCffs;
  };

  createTicketCff(ticket_cff) {
    return this._WebApi('ticket/cff', 'Create', { ticket_cff });
  }

  editTicketCff(ticket_cff) {
    return this._WebApi('ticket/cff', 'Update', { ticket_cff });
  }

  deleteTicketCff(no) {
    const data = {
      no,
    };
    return this._WebApi('ticket/cff', 'Delete', data);
  }

  findTicketFieldValuesByTicketNo = ({ ticket_no, with_relation_data = false }) => {
    const data = {
      ticket_no,
      with_relation_data,
    };

    return this._WebApi('ticket/fieldValue', 'GetByTicketNo', data);
  };

  createTicketFieldValue = (ticketFV) => {
    const data = {
      ticket_fv: ticketFV,
    };

    return this._WebApi('ticket/fieldValue', 'Create', data);
  };
}
