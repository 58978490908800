import i18n from 'i18next';

const AppMenuItems = (props) => {
    let { items, selectMenu, setSelectMenu, open, setOpen } = props;

    const onSelectedMenu = () => {
        setSelectMenu(items.title);
        if(selectMenu === items.title){
            setOpen(prevState => {
                return !prevState;
            });
        } else if (open === false && items.title !== '') {
            setOpen(true);
        }
    };

    return (
        <li className="menu-items">
            <button
                className={`${selectMenu === items.title ? 'underline' : ''} `}
                data-bs-toggle="collapse"
                href="#collapseMenu"
                role="presentation"
                aria-controls="collapseMenu"
                onMouseEnter={onSelectedMenu}
            >
                {i18n.t(`AppHeader.${items.title}`)}
            </button>
        </li>
    );
};

export default AppMenuItems;
