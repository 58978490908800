import React from 'react';
import { withTranslation } from 'react-i18next';
import PortalLayout from './PortalLayout';
import AdminLayout from './AdminLayout';
import { useLoaderData } from 'react-router-dom';

const CloudPortal = (props) => {
    const token = useLoaderData('root');

    return (
        token 
        ? <AdminLayout />
        : <PortalLayout />
    );
}

export default withTranslation()(CloudPortal);