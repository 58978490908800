import { produce } from 'immer';

const initState = {
  categories: [],
  category_class_by_category: {},
  category_by_project_no: {},
  category_class_by_category_no: [],
  ticket_template_data_by_category_class: {},
  ticket_template_by_category_class: {},
  errorcode: null,
};

const categoryClassReducer = produce((state = initState, action) => {
  switch (action.type) {
    case 'Error':
      // console.log(action)
      return {
        ...state,
        // tickets: null,
        errorcode: action.payload ? action.payload : 500,
      };
    case 'GetCategoryByNo':
      state.categories = [...state.categories, action.category];
      return state;
    // state.categories = [...state.categories, action.category];
    // console.log(state.categories)
    // return {
    //   ...state,
    //   categories: state.categories,
    //   errorcode: null,
    // };
    case 'GetTicketClassByCategoryNo':
      return {
        ...state,
        category_class_by_category: {
          ...state.category_class_by_category,
          [action.category_no]: action.classes, // 新增或更新該分類
        },
        errorcode: null,
      };
    case 'GetTicketClassesByCategoryNoOrder':
      return {
        ...state,
        category_class_by_category_no: action.payload,
        errorcode: null,
      };

    case 'GetCategoryByProjectNo':
      state.category_by_project_no[action.project_no] = action.categories;
      return state;
    // return {
    //   ...state,
    //   category_by_project_no: {
    //     ...state.category_by_project_no,
    //   },
    //   errorcode: null,
    // };
    case 'GetTicketTemplateDataByCategoryNoClassNo':
      const updatedTicketTemplateData = {
        ...state.ticket_template_data_by_category_class,
        [`${action.category_no}_${action.class_no}`]: action.template_data,
      };

      return {
        ...state,
        ticket_template_data_by_category_class: updatedTicketTemplateData,
        errorcode: null,
      };
    case 'GetTicketTemplateByCategoryNoClassNo':
      const updatedTicketTemplateByCategoryNoClassNo = {
        ...state.ticket_template_by_category_class,
        [`${action.category_no}_${action.class_no}`]: action.template_data,
      }
      return {
        ...state,
        ticket_template_by_category_class: updatedTicketTemplateByCategoryNoClassNo,
        errorcode: null,
      };
    default:
      return state;
  }
});

export default categoryClassReducer;
