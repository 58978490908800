import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import AppLoginMenu from './AppLoginMenu';
import Image from 'react-bootstrap/Image';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import { loginMenuAction } from '../../store/loginMenuSlice';
import { useDispatch } from 'react-redux';
import PublicIcon from '@mui/icons-material/Public';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from 'reactstrap';
import i18n from "i18next";

import { MsalProvider, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { createMsalConfig, logoutRequest } from "../../configs/AzureSSOauthConfig";

const AppLoginHeader = props => {
    const [accountOpen, setAccountOpen] = useState(false);
    const [langOpen, setLangOpen] = useState(false);
    const [language, setLanguage] = useState("zh-TW");

    const dispatch = useDispatch();
    const thirdMenu = localStorage.getItem("thirdMenu");

    useEffect(()=>{
        i18n.changeLanguage(language);
    },[language])

    useEffect(()=>{
        if(langOpen === true || accountOpen === true){
            dispatch(loginMenuAction.setMenuDropdownByVal(false));
        }
    },[langOpen, accountOpen])

    const accountToggle = () => setAccountOpen((prevState) => !prevState);
    const langToggle = () => setLangOpen((prevState) => !prevState);

    const changeLanguage = (lang) => {
        setLanguage(lang);
    }
    
    const handleDrawer = () => {
        dispatch(loginMenuAction.setSidebar());
    };

    const removeMenuLogHandler = () => {
        dispatch(loginMenuAction.removeAllMenu());
    }
   
    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
        })(({ theme, open }) => ({
            transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
            })
    }));
    
    const msalID = JSON.parse(localStorage.getItem('msal.config.id')) ? JSON.parse(localStorage.getItem('msal.config.id')) : {};
    const msalConfig = createMsalConfig(msalID.clientId, msalID.tenantId);
    const msalInstance = new PublicClientApplication(msalConfig);
    const isMsalAccount = localStorage.getItem('msal.account.keys');
    
    const SignInSignOutButton = () => {
        const { instance } = useMsal();
        return (
            <DropdownItem
                onClick={() => {
                    props.onLogout();
                    localStorage.removeItem("isAzureSSO");
                    instance.logoutRedirect(logoutRequest);
                }}
            >
                {i18n.t('AppHeader.logout')}
            </DropdownItem>
        );
    };

    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} >
            <Toolbar variant="dense" className= "AppLoginHeader" >
                <div className="nav-area">
                    {(thirdMenu!=="null" && thirdMenu!==null)
                    ?  (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawer}
                            edge="start"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        ):(
                            <IconButton
                                color="#D2D2D2"
                                aria-label="disable drawer"
                                edge="start"
                                sx={{ mr: 2 }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )
                    }
                        <Link to="/"  className="logo" onClick={()=>{removeMenuLogHandler()}}>
                            <Image alt="Cloudgoda" src="/assets/images/edc-cp-logo-50.png" rounded />
                        </Link>
                    <AppLoginMenu {...props} />
                </div>
                <div className="setting-area">
                    <Dropdown isOpen={langOpen} toggle={langToggle} {...props}>
                        <DropdownToggle
                            data-toggle="dropdown"
                            tag="span"
                            className='me-3'
                        >
                            <PublicIcon className="icon-setting" aria-label="language" />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => changeLanguage("zh-TW")}>繁體中文</DropdownItem>
                            {/* <DropdownItem onClick={() => changeLanguage("zh-CN")}>简体中文</DropdownItem> */}
                            <DropdownItem onClick={() => changeLanguage("en-US")}>English</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown isOpen={accountOpen} toggle={accountToggle} {...props}>
                        <DropdownToggle
                                data-toggle="dropdown"
                                tag="span"
                            >
                            <Image className="icon-acct" alt="account" src="/assets/images/ic_account_circle.svg" rounded />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <Link to="/user" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    {i18n.t('AppHeader.profile')}
                                </Link>
                            </DropdownItem>
                            {isMsalAccount ? (
                                <MsalProvider instance={msalInstance}>
                                    <SignInSignOutButton />
                                </MsalProvider>
                            ) : (
                                <DropdownItem onClick={props.onLogout}>
                                    {i18n.t('AppHeader.logout')}
                                </DropdownItem>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </div>                
            </Toolbar>
        </AppBar>

    );
}

export default AppLoginHeader;
