const initState = {
  teams_by_project: [],
  channels_by_team: [],
}

const teamsReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GetAllTeams_SUCCESS':
      return {
        ...state,
        teams_by_project: action.payload,
        errorcode: null,
      }
    case 'GetChannelByTeamId_SUCCESS':
      return {
        ...state,
        channels_by_team: action.payload,
        errorcode: null,
      }
    case 'Teams_Error':
      return {
        ...state,
        errorcode: action.payload ? action.payload : 500,
      }
    default:
      return state
  }
}
export default teamsReducer
