import { isNullOrUndefined } from '../../../js/Utils/utils'
import { InputBoxType } from '../../../js/Domain/CMDB/utils'

import { OzCI } from '../../../js/Domain/CMDB/struct'
import { CustomFieldOption, CustomFieldValue, OzFieldGroup } from '../Common/struct'
import { isEmptyString } from '../../../js/Utils/string'
import { OzTicket } from '../../../js/Domain/OzTicket'
import OzViewModel from '../../../js/Domain/Common/struct'

export class OzTicketCIRelation extends OzViewModel {
  constructor(
    no = null,
    ci_no = null,
    create_acct_no = null,
    create_dt = null,
    desc = null,
    ticket_no = null,
    field_no = null,
    ci = null,
    ticket = null
  ) {
    super()
    this.no = no
    this.ci_no = ci_no
    this.create_acct_no = create_acct_no
    this.create_dt = create_dt
    this.desc = desc
    this.ticket_no = ticket_no
    this.field_no = field_no
    this.ci = new OzCI().fromDict(ci)
    this.ticket = new OzTicket().fromDict(ticket)
  }

  getDesc() {
    return this.desc
  }

  fromDict(ticketDict) {
    super.fromDict(ticketDict)

    if (!isNullOrUndefined(ticketDict)) {
      this.ticket = new OzTicket().fromDict(ticketDict.ticket)
    }

    return this
  }
}

export class OzTicketCustomizeSetting extends OzViewModel {
  constructor(
    no = null,
    ticket_category_no = null,
    ticket_class_no = null,
    ticket_cff_no = null,
    enable_expected_minute = true,
    expected_minute = null,

    parent = null,
    ticket_category = null,
    ticket_class = null,
    ticket_cff = null
  ) {
    super()
    this.no = no
    this.ticket_category_no = ticket_category_no
    this.ticket_class_no = ticket_class_no
    this.ticket_cff_no = ticket_cff_no
    this.enable_expected_minute = enable_expected_minute
    this.expected_minute = expected_minute

    this.parent = parent
    this.ticket_category = ticket_category
    this.ticket_class = ticket_class
    this.ticket_cff = new OzTicketCff().fromDict(ticket_cff)
  }

  fromDict(tcSettingDict) {
    super.fromDict(tcSettingDict)

    if (!isNullOrUndefined(tcSettingDict)) {
      this.ticket_cff = new OzTicketCff().fromDict(tcSettingDict.ticket_cff)
    }
    return this
  }

  getValidExpectedMinuteParent = () => {
    let node = this
    while (node) {
      if (!isEmptyString(node.expected_minute)) {
        return node
      }
      node = node.parent
    }

    return null
  }

  getValidExpectedMinute = () => {
    const tcSetting = this.getValidExpectedMinuteParent()
    return tcSetting?.expected_minute ? tcSetting.expected_minute : null
  }

  calcExpectedTime = time => {
    let dt = new Date(time + this.getValidExpectedMinute() * 60 * 1000)
    if (dt) dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 18)
    return dt
    // return new Date(time + this.expected_minute * 60 * 1000);
  }
}

export class OzTicketCff extends OzViewModel {
  constructor(
    no = null,
    category_no = null,
    company_no = null,
    project_no = null,
    name = '',
    enable = true,
    deleted = false,
    create_time = null,
    field_groups = []
  ) {
    super()
    this.no = no
    this.category_no = category_no
    this.company_no = company_no
    this.project_no = project_no
    this.name = name
    this.enable = enable
    this.deleted = deleted
    this.create_time = create_time
    this.field_groups = field_groups.map(fg => new TicketCffFieldGroup().fromDict(fg)).sort((a, b) => a.order - b.order)
  }

  fromDict(ticketCffDict) {
    super.fromDict(ticketCffDict)

    if (!isNullOrUndefined(ticketCffDict)) {
      this.field_groups = (ticketCffDict.field_groups || []).map(fg => new TicketCffFieldGroup().fromDict(fg))
    }

    return this
  }

  getFields = (flatSubField = false) => {
    return this.field_groups.map(group => group.getFields(flatSubField)).flat()
  }

  filterDisable = () => {
    this.field_groups = this.field_groups.filter(fg => fg.enable)

    // 過濾 field group 的 field
    this.field_groups.forEach(fg => fg.filterDisable())
  }

  filterDelete = () => {
    this.field_groups = this.field_groups.filter(fg => !fg.deleted)

    // 過濾 field group 的 field
    this.field_groups.forEach(fg => fg.filterDelete())
  }

  filterTableColumn = () => {
    // 過濾 field group
    this.field_groups.forEach(fg => fg.filterTableColumn())
  }
}

export class OzTicketSecurityReport extends OzViewModel {
  constructor(
    no = null,
    ticket_no = null,
    project_no = null,
    submit_date = null,
    attack_source_ip = null,
    attack_source_port = null,
    attack_source_host = null,
    attack_source_country = null,
    attack_source_ipv6 = null,
    attack_dest_ip = null,
    attack_dest_port = null,
    attack_dest_host = null,
    attack_dest_country = null,
    attack_dest_ipv6 = null,
    virus_host = null,
    virus_name = null,
    happen_number = null,
    event_id = null,
    techniques = null,
    cloud_security_rule_no = null,
    kql_query = null,
    query_period = null,
    query_start_time = null,
    query_end_time = null,
    cht_name = null,
    cht_desc = null,
    eng_phenomenon = null,
    cht_phenomenon = null,
    eng_impact = null,
    cht_impact = null,
    eng_analysis_result = null,
    cht_analysis_result = null,
    eng_remediation = null,
    cht_remediation = null,
    attack_source_file_name = null,
    attack_source_file_path = null,
    attack_source_user_name = null,
    attack_source_file_hash = null,
    device_host = null,
    device_address = null,
    device_action = null,
    alert_product_name = null,
    alert_uri = null,
    attack_dest_user_name = null,
    attack_source_uri = null,
    attack_dest_uri = null,
    request_url = null,
    fusion_alert_event = null,
    alert_date_time = null,
    alert_provider_name = null,
    alert_type = null,
    alert_start_time = null,
    alert_end_time = null,
    alert_process_end_time = null,
    aip_client_ip = null,
    aip_client_location = null,
    aip_request_id = null,
    aip_detail = null,
    aip_timing = null,
    aip_detection = null,
    aip_tenant_login_source = null,
    destination_service_name = null,
    alert_link = null,
    sentinel_number = null,
    is_revise_user_name = null,
    revise_source_user_name = null,
    revise_dest_user_name = null,
    resource_subscription = null,
  ) {
    super()
    this.no = no
    this.ticket_no = ticket_no
    this.project_no = project_no
    this.submit_date = submit_date
    this.attack_source_ip = attack_source_ip
    this.attack_source_port = attack_source_port
    this.attack_source_host = attack_source_host
    this.attack_source_country = attack_source_country
    this.attack_source_ipv6 = attack_source_ipv6
    this.attack_dest_ip = attack_dest_ip
    this.attack_dest_port = attack_dest_port
    this.attack_dest_host = attack_dest_host
    this.attack_dest_country = attack_dest_country
    this.attack_dest_ipv6 = attack_dest_ipv6
    this.virus_host = virus_host
    this.virus_name = virus_name
    this.happen_number = happen_number
    this.event_id = event_id
    this.techniques = techniques
    this.cloud_security_rule_no = cloud_security_rule_no
    this.kql_query = kql_query
    this.query_period = query_period
    this.query_start_time = query_start_time
    this.query_end_time = query_end_time
    this.cht_name = cht_name
    this.cht_desc = cht_desc
    this.eng_phenomenon = eng_phenomenon
    this.cht_phenomenon = cht_phenomenon
    this.eng_impact = eng_impact
    this.cht_impact = cht_impact
    this.eng_analysis_result = eng_analysis_result
    this.cht_analysis_result = cht_analysis_result
    this.eng_remediation = eng_remediation
    this.cht_remediation = cht_remediation
    this.attack_source_file_name = attack_source_file_name
    this.attack_source_file_path = attack_source_file_path
    this.attack_source_user_name = attack_source_user_name
    this.attack_source_file_hash = attack_source_file_hash
    this.device_host = device_host
    this.device_address = device_address
    this.device_action = device_action
    this.alert_product_name = alert_product_name
    this.alert_uri = alert_uri
    this.attack_dest_user_name = attack_dest_user_name
    this.attack_source_uri = attack_source_uri
    this.attack_dest_uri = attack_dest_uri
    this.request_url = request_url
    this.fusion_alert_event = fusion_alert_event
    this.alert_date_time = alert_date_time
    this.alert_provider_name = alert_provider_name
    this.alert_type = alert_type
    this.alert_start_time = alert_start_time
    this.alert_end_time = alert_end_time
    this.alert_process_end_time = alert_process_end_time
    this.aip_client_ip = aip_client_ip
    this.aip_client_location = aip_client_location
    this.aip_request_id = aip_request_id
    this.aip_detail = aip_detail
    this.aip_timing = aip_timing
    this.aip_detection = aip_detection
    this.aip_tenant_login_source = aip_tenant_login_source
    this.destination_service_name = destination_service_name
    this.alert_link = alert_link
    this.sentinel_number = sentinel_number
    this.is_revise_user_name = is_revise_user_name
    this.revise_source_user_name = revise_source_user_name
    this.revise_dest_user_name = revise_dest_user_name
    this.resource_subscription = resource_subscription
  }

  fromDict(ticketSecurityReportDict) {
    super.fromDict(ticketSecurityReportDict)

    return this
  }
}

export class TicketCffFieldGroup extends OzFieldGroup {
  constructor(no = null, custom_field_group_no = null, ticket_cff_no = null, name = '', enable = true, deleted = false, order = null, fields = []) {
    super(no, name, enable, deleted, order, fields)

    this.custom_field_group_no = custom_field_group_no
    this.ticket_cff_no = ticket_cff_no
  }

  filterDisable = () => {
    this.fields = this.fields.filter(field => field.enable)

    // 過濾 field 的 items
    this.fields.forEach(field => field.filterDisable())
  }

  filterDelete = () => {
    this.fields = this.fields.filter(field => !field.deleted)

    // 過濾 field 的 items
    this.fields.forEach(field => field.filterDelete())
  }

  filterTableColumn = () => {
    this.fields = this.fields.filter(field => InputBoxType.Enum[field.input_box_type] !== InputBoxType.Enum.TableColumn)
  }

  fromDict(fgDict) {
    super.fromDict(fgDict)

    if (!isNullOrUndefined(fgDict)) {
      this.custom_field_group_no = fgDict.custom_field_group_no
      this.ticket_cff_no = fgDict.ticket_cff_no
    }

    return this
  }
}

export class TicketFieldValue extends CustomFieldValue {
  constructor(no = null, value = null, row_no = null, customFieldValueNo = null, ticketNo = null, fieldNo = null, field = null, options = []) {
    super(no, value, row_no, fieldNo, field, options)
    this.ticket_no = ticketNo
    this.custom_field_value_no = customFieldValueNo
  }

  fromDict(ticketFVDict) {
    super.fromDict(ticketFVDict)

    if (!isNullOrUndefined(ticketFVDict)) {
      this.options = (ticketFVDict.options || []).map(option => new CustomFieldOption().fromDict(option))
    }

    return this
  }
}
