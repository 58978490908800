import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import AppMenuItems from './AppMenuItems';
import { Row, Container } from 'react-bootstrap';
import menus from '../../configs/portal/portal-menus';
import AppMenuDropdown from './AppMenuDropdown';
import i18n from 'i18next';
import { ToastAlert } from '../../js/Base/ToastAlert';
import { CallWebApi } from '../../js/Base/CallWebApi';
import { BasePage } from '../../js/Base/BasePage';

import { MsalProvider, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { createMsalConfig, logoutRequest } from '../../configs/AzureSSOauthConfig';

const AppMenu = (props) => {
    const [selectMenu, setSelectMenu] = useState(undefined);
    const [open, setOpen] = React.useState(false);
    const [isEnableMFA, setIsEnableMFA] = useState(false);

    const [azureProcess, setAzureProcess] = useState(true);
    const [pageReady, setPageReady] = useState(true);
    const basePageInstance = new BasePage();

    const msalID = JSON.parse(localStorage.getItem('msal.config.id')) ? JSON.parse(localStorage.getItem('msal.config.id')) : {};
    const msalConfig = createMsalConfig(msalID.clientId, msalID.tenantId);
    const msalInstance = new PublicClientApplication(msalConfig);

    const pageLoading = () => {
        setPageReady(false);
    };

    const pageFinish = () => {
        setPageReady(true);
    };

    const onNoSelectHandler = () => {
        setSelectMenu(undefined);
        setOpen(false);
    };

    const startSSOToLogin = async (account) => {
        await CallWebApi(
            'acct',
            'LoginByAzureSSO',
            { id: account },
            null,
            (res) => {
                login_success_action(res.data.data);
            },
            (err) => {
                pageFinish();
                err_action('azure account login not success.');
            }
        );
    };
    
    const login_success_action = (res) => {
        if (res && res.id) {
            pageFinish();
            basePageInstance.LoginBase(res);

            // 取得登入帳號的角色與權限
            getComMembersByWebApi(res.no, res.token);
        } else {
            pageFinish();
            err_action('azure account login not success.');
        }
    };

    const err_action = (err) => {
        const toastAlert = new ToastAlert();
        pageFinish();
        toastAlert.autoClose(true).showError(i18n.t('LoginRegister.login-error-with-sso'));

        setTimeout(() => {
            login_fail_action();
            localStorage.removeItem("isAzureSSO");
            msalInstance.logoutRedirect(logoutRequest);
        }, 5000);
    };

    const login_fail_action = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("id");
        localStorage.removeItem("lan");
        localStorage.removeItem("name");
        localStorage.removeItem("no");
        localStorage.removeItem("timezone");
        localStorage.removeItem("company_no");
        localStorage.removeItem("project_no");
        localStorage.removeItem("company_name");
        localStorage.removeItem("expiration");
        localStorage.removeItem("company_list");
        localStorage.removeItem("project_name");
        localStorage.removeItem("project_list");
        localStorage.removeItem("category_no");
    };

    const getComMembersByWebApi = async (accountNo, token) => {
        await CallWebApi(
            'comp',
            'FindCompanysByAcct',
            { acct_no: accountNo },
            basePageInstance.GetWebApiConfig(token),
            (res) => {
                if (res) {
                    const comMembers = res.data.data;
                    const compNoList = comMembers.map((item) => item.no);
                    // 先取得登入帳號的所屬公司列表
                    if (compNoList) {
                        FindCompanysByNoList(compNoList, token, accountNo);
                    } else {
                        err_action('account not find company 3.');
                    }
                } else {
                    err_action('account not find company 1.');
                }
            },
            (err) => {
                err_action('account not find company 2.');
            }
        );
    };

    const FindCompanysByNoList = async (compNoList, token, acct_no) => {
        await CallWebApi(
            'comp',
            'FindCompanysByNoList',
            { no_list: compNoList },
            basePageInstance.GetWebApiConfig(token),
            (res) => {
                const companys = res.data.data;
                setIsEnableMFA(false);
                const config = companys.map((item) => item.configs);
        
                let isMFA = [];
                const getMFACompany = config.map((item) => {
                    item.find((comp) => {
                    if (comp.name === 'EnableMFA' && comp.value === 'true') isMFA = [...isMFA, comp];
                    });
                });
        
                if (companys && companys.length > 0) {
                    const company_no_list = [];
                    const companys_list = [];
                    companys.map((item, index, array) => {
                        company_no_list.push(item.no);
                        companys_list.push({ [item.no]: item.name });
                    });
                    localStorage.setItem('company_list', JSON.stringify(companys_list));
                    getProjectsByWebApi(companys, company_no_list, acct_no, token);
                } else {
                    err_action('account not find company 4.');
                }
            },
            (err) => {
                err_action('account not find company 5.');
            }
        );
    };

    const getProjectsByWebApi = async (company, company_no_list, acct_no, token) => {
        await CallWebApi(
            'proj',
            'FindProjectByAcctNo',
            { acct_no: acct_no },
            basePageInstance.GetWebApiConfig(token),
            (res) => {
                const projects = res.data.data;
                const current_project = projects[0];
                const company_no = projects.find((proj) => proj.no === Number(current_project.no)).company_no;
                localStorage.setItem('project_no', current_project.no);
                localStorage.setItem('project_name', current_project.name);
                localStorage.setItem('company_no', company_no);
                localStorage.setItem('company_name', company.find((comp) => comp.no === Number(company_no)).name);
                const projects_data = processData(company_no_list, projects);
                localStorage.setItem('project_list', JSON.stringify(projects_data));

                basePageInstance.GoIndexPage();
            },
            (err) => {
                err_action('company not find project.');
            }
        );
    };

    const processData = (company_no_list, project_list) => {
        let result = project_list.map((project) => ({
            company_no: company_no_list[project_list.indexOf(project)],
            projects: {
                project_no: project.no,
                project_name: project.name,
            },
        }));
        return result;
    };

    const logNetworkRequests = () => {
        const resources = performance.getEntriesByType("resource");
        const cachedDocuments = resources.filter(resource => 
            resource.name.includes('//login.microsoftonline.com/') && resource.initiatorType === 'fetch'
        );

        if (cachedDocuments.length > 0) {
            return true;
        }
        return false;
    }

    const AutoAzureSSOContent = () => {
        const { instance } = useMsal();
        const accLists = instance.getAllAccounts();

        if (accLists.length > 0 && azureProcess) {
            setAzureProcess(false);
            if (accLists[0].username != null && logNetworkRequests()) {
                pageLoading();
                startSSOToLogin(accLists[0].username);
            } else {
                err_action('Not the correct Azure token source.');
            }
        }
        return <></>;
    };

    return (
        <div className="AppMenu">
            <MsalProvider instance={msalInstance}>
                <AutoAzureSSOContent />
            </MsalProvider>
            <nav className="desktop-nav">
                <ul className="menus">
                    {menus.map((menu, index) => {
                        return (
                            <AppMenuItems
                                {...props}
                                items={menu}
                                key={'menu' + index}
                                selectMenu={selectMenu}
                                setSelectMenu={setSelectMenu}
                                open={open}
                                setOpen={setOpen}
                            />
                        );
                    })}
                </ul>
                <ul className="menus" style={{ paddingTop: '15px' }}>
                    <li>
                        <Link to={'/login'} style={{ textDecoration: 'none' }} onClick={onNoSelectHandler}>
                            <span className="text-nav-item" style={{ color: '#5F5F5F' }}>
                                {i18n.t('AppHeader.console')}
                            </span>
                        </Link>
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                        <button className="btn-primary btn-xs" type="submit" onClick={onNoSelectHandler}>
                            <Link to={{ pathname: '/login', search: `id=register` }} style={{ textDecoration: 'none', color: '#5F5F5F' }}>
                                {i18n.t('AppHeader.register')}
                            </Link>
                        </button>
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                        <Link
                            to={{ pathname: '/login', search: `id=login` }}
                            style={{ textDecoration: 'none' }}
                            onClick={onNoSelectHandler}
                        >
                            <span className="text-nav-item" style={{ color: '#5F5F5F' }}>
                                {i18n.t('AppHeader.login')}
                            </span>
                        </Link>
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                </ul>
            </nav>
            <div className="menu-dropdown" style={{ display: open ? 'block' : 'None' }}>
                <Container fluid>
                    <Row
                        className="menuitem-dropdown vertical-flex"
                        onMouseLeave={onNoSelectHandler}
                        style={{
                            height:
                                menus.filter((m) => m.title === selectMenu && m.submenu.length > 3).length > 0
                                    ? '300px'
                                    : 'auto',
                        }}
                    >
                        {selectMenu !== '' &&
                            menus.map((menu, index) => {
                                if (menu.title === selectMenu) {
                                    return (
                                        <AppMenuDropdown
                                            {...props}
                                            submenu={menu.submenu}
                                            key={'menu' + index}
                                            setOpen={setOpen}
                                        />
                                    );
                                } else {
                                    return '';
                                }
                            })}
                    </Row>
                </Container>
            </div>
            <div></div>
        </div>
    );
};

export default AppMenu;
