import { loginMenuAction } from '../../store/loginMenuSlice';
import { useSelector, useDispatch } from 'react-redux';
import { isNullOrUndefined } from "../../js/Utils/utils";
import i18n from 'i18next';

const AppLoginMenuItems = (props) => {
    const { selectMenu, items  } = props;
    const menuDropdownOpen = useSelector((state) => state.loginMenu.menuDropdownOpen);
    const firstMenu = localStorage.getItem("selectedFirstMenu");
    const dispatch = useDispatch();
    
    const onSelectedMenu = () => {
        dispatch(loginMenuAction.setFirstMenu(items.title));

        if(selectMenu === items.title){
            dispatch(loginMenuAction.setMenuDropdown());
            
        } else if (menuDropdownOpen === false && items.title !== '') {
            dispatch(loginMenuAction.setMenuDropdownByVal(true));
        }
    };

    const onMouseEnterMenu = () => {
        if(menuDropdownOpen === true){
            dispatch(loginMenuAction.setFirstMenu(items.title));
        }
    }

    return (
        <li className="menu-items">
            <button
                className={`${(isNullOrUndefined(selectMenu) ? firstMenu : selectMenu) === items.title ? 'underline' : ''} `}
                data-bs-toggle="collapse"
                href="#collapseMenu"
                role="presentation"
                aria-controls="collapseMenu"
                onClick={onSelectedMenu}
                onMouseEnter={onMouseEnterMenu}
            >
                {i18n.t(`AppHeader.${items.title}`)}
            </button>
        </li>
    );
};

export default AppLoginMenuItems;
