import React, {Fragment} from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppHeader from '../components/portal/AppHeader';
import AppContent from '../components/portal/AppContent';
import MobileButton from '../components/portal/MobileButton';
import AppFooter from '../components/portal/AppFooter';

const PortalLayout = (props) => {

    return (
        <Fragment>
            <ToastContainer position="top-center" />
            <AppHeader {...props} />
            <MobileButton key="edc-mobile-button"  {...props} />
            <AppContent {...props} />
            <AppFooter {...props} />
        </Fragment>
    );
}

export default PortalLayout;