import { combineReducers } from "redux";

import authReducer from "./account/authReducer";
import inviteReducer from "./base/inviteReducer";
import NavReducer from "./base/navReducer";
import todoListReducer from "./ticket/todoListReducer";
import unclosedListReducer from "./ticket/unclosedListReducer";
import projectReducer from "./project/projectReducer";
import companyReducer from "./company/companyReducer";
import companyGroupReducer from "./company/companyGroup/companyGroupReducer";
import companyGroupOrgChartReducer from "./company/companyGroup/companyGroupOrgChartReducer";
import acctReducer from "./account/acctReducer";
import ticketStatusReducer from "./ticket/ticketStatusReducer";
import categoryClassReducer from "./category/categoryClassReducer";
import ticketTemplateReducer from "./ticket/ticketTemplateReducer";

import reportReducer from "./report/reportReducer";
import grafanaReducer from "./report/grafanaReducer";
import ticketLevelReducer from "./ticket/ticketLevelReducer";
import ticketReducer from "./ticket/ticketReducer";
import cmdbPeopleGroupCIReducer from "./CMDB/CmdbPeopleGroupCIReducer";
import cmdbPeopleCIReducer from "./CMDB/CmdbPeopleCIReducer";
import escalationReducer from "./escalation/escalationReducer";
import ticketEventReducer from "./ticket/ticketEventReducer";
import msgReducer from "./msg/msgReducer";
import ticketBindTicketReducer from "./ticket/ticketBindTicketReducer";
import sourceSecurityRuleReducer from "./cloud/sourceSecurityRuleReducer";
import clientSecurityRuleReducer from "./cloud/clientSecurityRuleReducer";
import cloudAppKeyReducer from "./cloud/cloudAppKeyReducer";
import cloudLogAnalyticsWorkspacesReducer from "./cloud/cloudLogAnalyticsWorkspacesReducer";
import employeeScheduleReducer from "../../store/reducers/employeeSchedule/EmployeeScheduleReducer";
import loginMenuReducer from "../loginMenuSlice";

import godaSecurityReducer from "./Goda/security/securityReducer";
import teamsReducer from './cloud/teamsReducer';

const rootReducer = combineReducers({
  //登入
  auth: authReducer,
  //公司邀請
  invite: inviteReducer,
  //Menu
  nav: NavReducer,

  //工作清單
  workList: todoListReducer,

  // 待結案清單
  unclosedList: unclosedListReducer,

  //專案 商業流程
  project: projectReducer,

  // 公司
  company: companyReducer,

  //公司群組
  companyGroup: companyGroupReducer,

  //公司群組組織圖
  companyGroupOrgChart: companyGroupOrgChartReducer,

  //人員
  acct: acctReducer,

  //單子狀態
  ticketStatus: ticketStatusReducer,

  //流程分類
  categoryClass: categoryClassReducer,

  //Ticket 範本
  ticketTemplate: ticketTemplateReducer,

  //系統報表
  report: reportReducer,

  //Grafana Dashboard
  grafana: grafanaReducer,

  //單子分級
  ticketLevel: ticketLevelReducer,

  //就是單子
  ticket: ticketReducer,

  //CMDB 的 人員群組
  cmdbPeopleGroupCI: cmdbPeopleGroupCIReducer,

  //CMDB 的 人員
  cmdbPeopleCI: cmdbPeopleCIReducer,

  // escalation
  escalation: escalationReducer,

  // ticket event
  ticketEvent: ticketEventReducer,

  //訊息
  msg: msgReducer,

  //綁單
  bindTicket: ticketBindTicketReducer,

  //原始不重複資安警訊規則管理
  sourceSecurityRule: sourceSecurityRuleReducer,

  //客戶資安警訊規則管理
  clientSecurityRule: clientSecurityRuleReducer,

  // CloudAppKey
  cloudAppKey: cloudAppKeyReducer,

  // CloudLogAnalyticsWorkspaces
  cloudLogAnalyticsWorkspaces: cloudLogAnalyticsWorkspacesReducer,

   // Teams相關
  teams: teamsReducer,

  // EmployeeSchedule
  employee_schedule: employeeScheduleReducer,

  // Login Menu
  loginMenu: loginMenuReducer,

  // Goda相關
  // Security
  godaSecurity: godaSecurityReducer,
});

export default rootReducer;
